import { ProductTypes } from '@config';

export const SUPPORTS_MCL: string[] = [
  ProductTypes.LoaderCranes,
  ProductTypes.ContainerHandlingSystems,
];
export const SUPPORTS_SYSTEM_COMMUNICATION: string[] = [
  ProductTypes.AccessPlatforms,
  ProductTypes.ContainerHandlingSystems,
];
